import React, { useEffect, useState } from "react"

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import Layout from '../../layouts/Website'
import Paper, { PaperClean } from '../../components/Paper'
import Heading, { HeadingBase } from '../../components/Heading'

import Form from '../../views/web/Contact/Form'
import FormAuth from '../../views/web/Contact/FormAuth'

import Info from '../../views/web/Contact/Info'
import Messages from '../../views/web/Contact/Messages'

import { useAuth } from '../../context'

const useStyles = makeStyles((theme) => ({
  link: {
    color: '#fff',
    textDecoration: 'none',
    margin: '0px 0px 0px 0px'
  },
  linkTypo: {
    margin: '0px 0px 100px 0px'
  }
}));

const info = {
  title: 'ROTTERDAM, THE NETHERLANDS',
  subtitles: [
    '---',
    'Art Projects',
    'Business Solutions',
    'Security Testing / Consulting',
    '---',
    'Write down your requirements, and we will come up with an optimal solution.',
    '---',
    '** register, confirm email and setup a profile to access the direct chat',
  ],
  options: [
    'React',
    'JS | Python | GO | Bash'
  ],
}

const IndexPage = () => {
  const classes = useStyles();
  const { user, profile } = useAuth()

  const [ messages, setMessages ] = useState([])
  const [ error, setError ] = useState('')

  const loadMessages = () => {
    user.getIdToken(/* forceRefresh */ true).then((idToken) => {
      let data = {
        token: idToken,
      }
      try {
        fetch(`${process.env.GATSBY_API_URL}/skllchain/messages`, {
          method: 'POST',
          body: JSON.stringify(data),
          headers: { 'Content-Type': 'application/json' },
        })
        .then(response => response.json())
        .then(data => {
          if (data.status === 0) {
            setMessages(data.messages)
          } else {
            setError('Something went wrong.')
          }
        })
        .catch(() => {
          setError('Something went wrong.')
        });
      } catch {
        setError('Something went wrong.')
      }
    }).catch(() => {
      setError('Something went wrong.')
    });
  }

  if (user && profile && profile.is_setup) {
    return (
      <Layout>
        <Grid item xs={12}>
          <Paper>
            <HeadingBase title='Direct Chat' />
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper>
            <Grid container>
              <Grid item xs={12} sm={7}>
                <PaperClean>
                <Messages error={error} messages={messages} loadMessages={loadMessages}/>
                </PaperClean>
              </Grid>
              <Grid item xs={12} sm={5}>
                <PaperClean>
                  <FormAuth loadMessages={loadMessages}/>
                </PaperClean>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Layout>
    )
  } else {
    return (
      <Layout>
          <Grid item xs={12}>
            <Paper>
              <Heading title='Get in touch' />
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper>
              <Grid container>
                
                <Grid item xs={12} sm={6}>
                  <PaperClean>
                  <Form />
                  </PaperClean>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <PaperClean>
                    <Info data={info} />
                  </PaperClean>
                </Grid>

              </Grid>
            </Paper>
          </Grid>

      </Layout>
    )
  }
}

export default IndexPage
