import React, { useState } from "react"

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import FieldTextControlled from '../../../components/Form/FieldTextControlled'
import FieldMultiline from '../../../components/Form/FieldMultiline'

const initial = {
  display_name: '',
  email: '',
  website: '',
  instagram: '',
  message: ''
}

const IndexPage = () => {
  const [address, setAddress] = useState('')
  const [error, setError] = useState('')
  const [sent, setSent] = useState(false)
  const [ticket, setTicket] = useState(initial)

  const clearForm = () => {
    setTicket({ ...initial });
  };

  const submit = e => {
    e.preventDefault()
    try {
      fetch(`${process.env.GATSBY_API_URL}/skllchain/anon/create-ticket`, {
        method: 'POST',
        body: JSON.stringify(ticket),
        headers: { 'Content-Type': 'application/json' },
      })
      .then(res => res.json())
      .then(json => {
        if (json.status === 0) {
          setAddress(ticket.email)
          clearForm()
          setSent(true)
        } else {
          setError('Oops, something went wrong.')
        }
      })
    } catch {
      setError('Oops, something went wrong.')
    }
  }

  const setDisplayName = (value) => {
    setTicket({...ticket, display_name: value})
  }
  const setEmail = (value) => {
    setTicket({...ticket, email: value})
  }
  const setWebsite = (value) => {
    setTicket({...ticket, website: value})
  }
  const setInstagram = (value) => {
    setTicket({...ticket, instagram: value})
  }
  const setMessage = (value) => {
    setTicket({...ticket, message: value})
  }

  return (
    <>
    {!sent ? (
      <form onSubmit={submit}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FieldTextControlled
              variant='outlined'
              label="Name"
              name="display_name" type="text"
              value={ticket.display_name} setValue={setDisplayName}
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <FieldTextControlled
              variant='outlined'
              label="Email"
              name="email" type="email"
              value={ticket.email} setValue={setEmail}
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldTextControlled
              variant='outlined'
              label="Website"
              name="website" type="text"
              value={ticket.website} setValue={setWebsite}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldTextControlled
              variant='outlined'
              label="Instagram"
              name="instagram" type="text"
              value={ticket.instagram} setValue={setInstagram}
            />
          </Grid>
          <Grid item xs={12}>
            <FieldMultiline
              label="Message"
              name="message" 
              value={ticket.message} setValue={setMessage}
              required={true}
            />
          </Grid>
        </Grid>
      <Button
        type="submit"
        color='primary'
        variant='outlined'
      >
        Send Message
      </Button>
    </form>
    ):(
      <>
      {error ? (
      <>
      <Typography variant='body2' component='p'>
        Ooops, smth went wrong :(
      </Typography>
      <Button
        color="primary"
        onClick={() => {
          setError(false);
          setSent(false)
        }}
      >
        Try again
      </Button>
      </>
      ):
      (
      <div>
      <Typography variant='body1' component='p'
        paragraph align='left'
      >
        Success!
        <br/>
        Your email: <b>{address}</b>
      </Typography>
      <Button
        color="primary"
        onClick={() => {
          setAddress('')
          setSent(false)
        }}
      >
        Send another message
      </Button>
      </div>
      )
      }
      </>
      )}
    </>
  )
}

export default IndexPage
