import React, { useState } from "react"

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import FieldMultiline from '../../../components/Form/FieldMultiline'

import { useAuth } from '../../../context'

const initial = {
  message: ''
}

const IndexPage = ({loadMessages}) => {
  const { user } = useAuth()
  const [error, setError] = useState('')
  const [payload, setPayload] = useState(initial)

  const clearForm = () => {
    setPayload({ ...initial });
  };

  const submit = e => {
    setError('')
    e.preventDefault()
    user.getIdToken(/* forceRefresh */ true).then((idToken) => {
      let data = {
        token: idToken,
        message: payload.message
      }
      try {
        fetch(`${process.env.GATSBY_API_URL}/skllchain/messages`, {
          method: 'PUT',
          body: JSON.stringify(data),
          headers: { 'Content-Type': 'application/json' },
        })
        .then(response => response.json())
        .then(data => {
          if (data.status === 0) {
            loadMessages()
            clearForm()
          } else {
            setError('Something went wrong.')
          }
        })
        .catch(() => {
          setError('Something went wrong.')
        });
      } catch {
        setError('Something went wrong.')
      }
    }).catch(() => {
      setError('Something went wrong.')
    });
  }
  const setMessage = (value) => {
    setPayload({...payload, message: value})
  }
  return (
    <>
      { error && (
      <>
      <Typography variant='body2' component='p'
        paragraph align='left'
      >
        ERROR. {error}
      </Typography>
      <Button
        color='primary'
        onClick={() => setError('')}
      >
        Dismiss
      </Button>
      </>
      )}
      <form onSubmit={submit}>
        <Grid container>
        <Grid item xs={12}>
            <FieldMultiline
              label="Message"
              name="message" 
              value={payload.message} setValue={setMessage}
              required={true}
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          color='primary'
          variant='outlined'
        >
          Send Message
        </Button>
    </form>
  </>
  )
}

export default IndexPage
