import React, { useEffect, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    background: 'transparent'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  pos: {
    marginBottom: 12,
  },
});

export default function OutlinedCard({loadMessages, messages, error}) {
  const classes = useStyles();

  useEffect(() => {
    loadMessages()
    const interval = setInterval(() => {
      loadMessages()
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Card className={classes.root} elevation={0}>
      <CardContent>
        <Typography variant='subtitle1' component='h1'
        className={classes.title} gutterBottom
        align="left"
        >
          Recent Messages
        </Typography>
        <hr />

        {error ? (
          <Typography
            variant="body1" component="p"
            align="left"
          >
          {error}
          </Typography>
        ) : (
          <>
            {messages.map((obj, index) => {
              return (
                <div key={index}>
                  <Typography 
                    variant="body1" component="p"
                    align="left"
                    style={{fontSize: '10px'}}
                  >
                    [{obj.display}] {obj.created_at.replace(/T/g, " ").slice(0, -5)}
                  </Typography>
                  <Typography 
                    variant="body2" component="p"
                    align="left"
                    paragraph
                  >
                    {obj.message}
                  </Typography>
                </div>
              )

            })}
          </>
        )}

      </CardContent>
    </Card>
  );
}
